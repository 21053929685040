import React, { useEffect, useState } from "react";
import { useGlobalState } from "../useGlobalState";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
// @ts-ignore
import DialogIepApp from "../components/DialogIepApp.tsx";

import { Slide } from "@mui/material";
// import Link from "@mui/material/Link";

import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { TransitionProps } from "@mui/material/transitions";
import axios from "axios";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Home = () => {
  const [state, dispatch] = useGlobalState();

  const [persoon, setPersoon] = useState("");
  const [personen, setPersonen] = useState([]);
  const [pincode, setPincode] = useState("");
  const [dialogopen, setDialogOpen] = useState(false);
  const navigate = useNavigate();

  // let currentSessionValues = JSON.parse(
  //   sessionStorage.getItem("iep_app_session")
  // );

  const onClose = () => {
    setDialogOpen(false);
  };

  // const clickAddUser = () => {
  //   navigate("/adduser");
  // };

  const AanmeldButton = () => {
    return (
      <div className="ml-2">
        <Button
          sx={{ width: 150 }}
          variant="contained"
          onClick={clickAanmelden}
        >
          Aanmelden
        </Button>
      </div>
    );
  };

  const clickAanmelden = () => {
    if (persoon.pin === pincode) {
      console.log(
        "pincode klopt, zet in session en ga door naar de volgende pagina"
      );

      AanmeldenLogin();

      // sessionStorage.setItem("iep_app_session", JSON.stringify(persoon));
      // //TODO MOET DIT WEL??
      // dispatch({
      //   type: "SET_USER_LOGGEDIN",
      //   payload: persoon,
      // });
      // navigate("/menu");
    } else {
      console.log(
        "pincode klopt niet, geef een melding en voer opnieuw in, maak session leeg"
      );
      setDialogOpen(true);
    }
  };

  const AanmeldenLogin = () => {
    sessionStorage.setItem("iep_app_session", JSON.stringify(persoon));
    async function fetchData() {
      var data = JSON.stringify({
        id: persoon.id,
        naam: persoon.naam,
      });
      var config;
      config = {
        method: "post",
        url: "https://iep.dordtonline.nl/api/llogin.php",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
        data: data,
      };
      await axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          dispatch({
            type: "SET_USER_LOGGEDIN",
            payload: persoon,
          });
          navigate("/menu");
        })
        .catch(function (error) {
          console.log(error);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    }
    fetchData();
  };

  ////////////////////////
  //TODO MOET DIT WEL??
  // dispatch({
  //   type: "SET_USER_LOGGEDIN",
  //   payload: persoon,
  // });
  // navigate("/menu");

  const handleChange = (event: SelectChangeEvent) => {
    console.log("Je koos:", event.target.value);
    setPersoon(event.target.value);
  };

  const handlePinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPincode(event.target.value);
  };

  // const clickAfmelden = () => {
  //   console.log("afmelden click");
  //   sessionStorage.removeItem("iep_app_session");
  // };

  useEffect(() => {
    async function fetchData() {
      var data = JSON.stringify({
        id: 1,
      });
      var config;
      config = {
        method: "post",
        url: "https://iep.dordtonline.nl/api/lnamen.php",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
        data: data,
      };
      await axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          setPersonen(response.data);
        })
        .catch(function (error) {
          console.log(error);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    }
    fetchData();
  }, []);

  return (
    <>
      <br></br>

      <div className="text-center ">
        <h1 className="mt-0 mb-2 text-2xl font-normal leading-normal text-pink-900">
          Welkom bij de Iep App
        </h1>
        <h1 className="mt-0 mb-2 text-2xl font-normal leading-normal text-pink-900">
          Voer je naam en pincode in:
        </h1>
        <br></br>
        <FormControl sx={{ margin_left: 50 }}>
          <InputLabel id="demo-simple-select-label">Naam</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={persoon}
            label="Naam"
            autoFocus
            onChange={handleChange}
          >
            {personen.map((pers) => (
              <MenuItem key={pers.id} value={pers}>
                {pers.naam}
              </MenuItem>
            ))}
          </Select>

          <br></br>

          <TextField
            id="outlined-password-input"
            label="Pincode"
            variant="outlined"
            onChange={handlePinChange}
          />
          <br></br>
        </FormControl>

        <AanmeldButton />
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <h1 className="mt-0 mb-2 text-1xl font-normal leading-normal text-sky-800">
          Welkom bij de Iep App!
        </h1>
        <EmojiEmotionsIcon />
      </div>
      <DialogIepApp
        icons={<SentimentVeryDissatisfiedIcon />}
        Transition={Transition}
        dialogopen={dialogopen}
        onClose={onClose}
        koptekst={"persoon of pincode fout?"}
        melding={"Voer de juiste persoon en pincode in."}
      />
    </>
  );
};

export default Home;
