//reducer
function reducer(state, action) {
  switch (action.type) {
    case 'FETCH_USERS':
      state = { ...state };
      state.users = action.payload;
      return state;
    case 'FETCH_LOCATIES':
      state = { ...state };
      state.locaties = action.payload;
      return state;
      
    case 'FETCH_ITEMS':
      state = { ...state };
      state.items = action.payload;
      return state;

    case 'GET_LOCATIE':
      state = { ...state };
      state.getlocatie = action.payload;
      return state;
    case 'SET_LOCATIE_NULL':
      state = { ...state };
      state.getlocatie = null;
      return state;


    //verderop kan het straks weg, check
    case 'SET_USER_LOGGEDIN':
      state.logged_in_user = action.payload;
      return state;

    case 'SET_JWT_INLOG_TOKEN':
      state = { ...state };
      state.jwtToken = action.payload.jwt;
      return state;

    case 'SET_SELECTEDCAN':
      state = { ...state };
      state.hasCansData = (action.payload.cans.length !== 0);
      if (action.payload.cans !== undefined) {
        if (action.payload.cans.length > 0) {
          let teller = 0;
          state.canSelected = action.payload.cans;
          state.canSelected.FoundFridgeCanID = action.payload.id;
          state.canSelected.forEach(function (element) {
            teller = teller + 1;
            element.uniqueID = teller;
          });
        } else {
          state.canSelected = []
        }
      }
      return state;
    case "SET_FRIDGECANHASDATA":
      state = { ...state };
      state.hasCansData = action.payload;
      return state;

    case "FETCH_PAYMENTREQUEST":
      state = { ...state };
      state.paymentRequestLink = action.payload;
      return state;

    case "FETCH_PAYMENTREQUEST_OMNIKASSA":
      state = { ...state };
      state.paymentRequestLinkOmnikassa = action.payload;
      return state;

    case "FETCH_PAYMENTREQUEST_OMNIKASSA_CHECKSTATUS":
      state = { ...state };
      state.paymentRequestLinkOmnikassaCheckStatus = action.payload;
      return state;

    case "SET_AUTOMAATNUMMER":
      state = { ...state };
      state.automaatNummer = action.payload;
      return state;

    case "FETCH_PAYMENTREQUEST_OMNIKASSA_FRIDGE_OPENED":
      state = { ...state };
      state.FridgeOpenStatus = action.payload;
      return state;

    case "SET_KORTINGSCODE":
      state = { ...state };
      state.kortingsCode = action.payload;
      return state;

    case "SET_KORTINGSCODEFOUT":
      state = { ...state };
      state.kortingsCodeFout = action.payload;
      return state;

    case "CMDLINE":
      return state = "/tab1";

    case "GETFRIDGECAN":
      return [...state, { "can": 123 }];

    case "FETCH_FRIDGECANSHAFT":
      state = { ...state };
      state.hasCansData = (action.payload.cans.length !== 0);
      if (action.payload.cans !== undefined) {
        if (action.payload.cans.length > 0) {
          state.cans = action.payload.cans;
          state.cans.FoundFridgeCanID = action.payload.id;
          let tellert = 0;
          state.cans.forEach(function (element) {
            tellert = tellert + 1;
            element.uniqueID = tellert;
          });
        } else {
          state.cans = []
        }
      }
      return state;
    default:
      return state
  }
}
export default reducer;