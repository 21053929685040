import React, { useEffect, useState } from "react";
import { useGlobalState } from "../useGlobalState";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";

import axios from "axios";

const Items = () => {
  const [state, dispatch] = useGlobalState();
  const navigate = useNavigate();
  const location = useLocation();
  const [itemslist, setItemsList] = useState([]);

  let currentSessionPersoon = JSON.parse(
    sessionStorage.getItem("iep_app_session")
  );

  const clickItemToevoegen = () => {
    console.log("Toevoegen item click");
    navigate("/additem", {
      state: {
        state: state,
        itemID: null,
        locatieID: location.state ? location.state.locatieID : null, //hiero
        locatieObject: location.state ? location.state.locatieObject : null, //hiero
      },
    });
  };

  const clickToggleUser = (event, itemid) => {
    console.log("click Toggle User", itemid.itemID, currentSessionPersoon.id);
    ToggleUser(itemid.itemID, currentSessionPersoon.id);
    //setScrollPosition(myWindow.pageYOffset);
    //window.scrollTo(0, this.state.scrollPosition);
  };

  const ToggleUser = (item_id, user_id) => {
    async function fetchData() {
      var data = JSON.stringify({
        item_id: item_id,
        user_id: user_id,
      });
      var config;
      if (1 === 2) {
        config = {
          method: "post",
          url: "https://iep.dordtonline.nl/api/toggleadditemuserZZZ.php",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
          data: data,
        };
      } else {
        config = {
          method: "post",
          url: "https://iep.dordtonline.nl/api/ltoggleadditemuser.php",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
          data: data,
        };
      }
      await axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          GetListItems();
        })
        .catch(function (error) {
          console.log(error);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    }
    fetchData();
  };

  const clickTerugButton = () => {
    navigate("/locaties");
  };

  const ListItemsItem = (item) => {
    // var naamlijst = "niet gebruikt";
    return (
      <div className="flex mx-2 mb-2 bg-white shadow-2xl">
        <div
          className="flex-none w-14 bg-slate-500 text-white p-1"
          onClick={(event) => clickEditItem(event, item.item)}
        >
          {item.item.locatieletter}
          {item.item.itemnummer}
        </div>
        <div
          className="md:flex flex-auto "
          onClick={(event) => clickEditItem(event, item.item)}
        >
          <div className="md:border-r md:border-slate-500 border-red-800 p-1 ">
            {item.item.itemnaam}
          </div>
          <div className="p-1 text-red-900">{item.item.namen}</div>
        </div>
        <EditItemButton item={item.item} />
        <ToggleUserButton itemID={item.item.id} />
      </div>
    );
  };

  const ToggleUserButton = (itemID) => {
    return (
      <>
        <div className="flex-none p-1 justify-center">
          <button
            className="text-white rounded-full bg-blue-400 p-1"
            onClick={(event) => clickToggleUser(event, itemID)}
            key={itemID}
          >
            +/-
          </button>
        </div>
      </>
    );
  };

  const EditItemButton = (item) => {
    if (state.logged_in_user && state.logged_in_user.isadmin === "1") {
      return (
        <>
          <div className="flex-none p-1 justify-center">
            <button
              className="text-white rounded-full bg-blue-400 p-1"
              onClick={(event) => clickEditItem(event, item.item)}
              // key={locatieID}
            >
              &nbsp;Edit&nbsp;
            </button>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };

  const clickEditItem = (event, pItem) => {
    if (state.logged_in_user && state.logged_in_user.isadmin === "1") {
      console.log("click Select item, navigeer naar edit items", pItem, event);
      navigate("/additem", {
        state: {
          state: state,
          item: pItem,
          itemID: pItem.id,
          locatieID: location.state ? location.state.locatieID : null, //hiero
          locatieObject: location.state ? location.state.locatieObject : null, //hieroß
        },
      });
    } else {
      console.log("click Select item, alleen ADMIN mag naar edit items");
    }
  };

  const ListItems = () => {
    if (Array.isArray(itemslist) && itemslist.length !== 0) {
      var teller = 0;
      return (
        <>
          {itemslist.map((item) => (
            <ListItemsItem item={item} key={teller++} />
          ))}
        </>
      );
    } else {
      return (
        <div>
          <h1>De lijst is nog leeg, voeg een item toe.</h1>
        </div>
      );
    }
  };

  // const KopTekst = () => {
  //   return (
  //     <>
  //       <h1 className="fixed top-12 ml-2 mt-0 mb-2 text-2xl font-normal leading-normal text-pink-800">
  //         Items
  //       </h1>
  //     </>
  //   );
  // };
  const KopTekst = () => {
    return (
        <h1 className="text-4xl text-red-500">Items</h1>
    );
  };

  const ToevoegenButton = () => {
    if (state.logged_in_user && state.logged_in_user.isadmin === "1") {
      return (
        // <>
        //   <div className="fixed top-10 mt-2 ml-24">
            <Button
              sx={{ width: 100 }}
              variant="contained"
              onClick={clickItemToevoegen}
            >
              Toevoegen
            </Button>
        //   </div>
        // </>
      );
    } else {
      return <></>;
    }
  };

  const TerugButton = () => {
    return (
      // <>
      //   <div className="fixed top-10 mt-2 ml-56 ">
          <Button
            sx={{ width: 100 }}
            variant="contained"
            onClick={clickTerugButton}
          >
            Terug
          </Button>
      //   </div>
      // </>
    );
  };

  useEffect(() => {
    GetListItems();
  }, []);

  const GetListItems = () => {
    if (!state.logged_in_user) {
      navigate("/");
    }

    async function fetchData() {
      var data = JSON.stringify({
        itemID: null,
        locatieID: location.state ? location.state.locatieID : null, //hiero
      });
      var config;
      if (1 === 2) {
      } else {
        config = {
          method: "post",
          url: "https://iep.dordtonline.nl/api/litems.php",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
          data: data,
        };
      }
      await axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          //hihi
          let namenlist = "";
          let prev_id = "";
          let tmplist = [];
          let newlist = [];
          for (let itm of response.data) {
            if (prev_id !== itm.id) {
              if (prev_id === "") {
                prev_id = itm.id;
                tmplist = {
                  id: itm.id,
                  itemnaam: itm.itemnaam,
                  itemnummer: itm.itemnummer,
                  locatieid: itm.locatieid,
                  locatieletter: itm.locatieletter,
                  namen: "",
                };
              } else {
                tmplist.namen = namenlist.substring(2);
                newlist.push(tmplist);
                tmplist = {
                  id: itm.id,
                  itemnaam: itm.itemnaam,
                  itemnummer: itm.itemnummer,
                  locatieid: itm.locatieid,
                  locatieletter: itm.locatieletter,
                  namen: "",
                };
                namenlist = "";
              }
              prev_id = itm.id;
            }
            namenlist = namenlist + ", " + (itm.naam !== null ? itm.naam : "");
          }
          if (tmplist.length !== 0) {
            tmplist.namen = namenlist.substring(2);
            newlist.push(tmplist);
          }
          setItemsList(newlist);
          //    setMyWindow(window);
          dispatch({
            type: "FETCH_ITEMS",
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    }
    fetchData();
  };

  return (
    <>
      <div className="flex flex-col fixed top-0 bg-white w-full justify-center items-center space-y-4 py-4 bg-indigo-200 ">
        <KopTekst />
        <div className="flex flex-row space-x-4">

          {/* <div className="rounded-md bg-blue-500 text-white px-4 py-2">
            Hallo knop 1
          </div>

          <div className="rounded-md bg-blue-500 text-white px-4 py-2">
            Hallo knop 2
          </div> */}
        <ToevoegenButton />
        <TerugButton />

        </div>
      </div>
      {/* <div className="max-w-3xl mx-auto mt-12 text-slate-800">
        <KopTekst />
        <ToevoegenButton />
        <TerugButton />
      </div> */}
      <div className="max-w-3xl mx-auto mt-32 text-slate-800 ">
        {/* <br></br>
        <br></br>
        <br></br> */}
        <ListItems />
      </div>
    </>
  );
};

export default Items;
