import React, { useEffect, useState } from "react";
import { useGlobalState } from "../useGlobalState";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Fab from "@mui/material/Fab";
import TextareaAutosize from "@mui/material/TextareaAutosize";
// import IconButton from "@mui/material/IconButton";
// import Box from "@mui/material/Box";
// @ts-ignore
import DialogIepApp from "../components/DialogIepApp.tsx";

import { Slide, Stack } from "@mui/material";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import axios from "axios";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddItem = (route, navagation) => {
  const [state, dispatch] = useGlobalState();
  const navigate = useNavigate();
  const location = useLocation();
  const [ppersonen, setPpersonen] = useState([]);
  const [persoon, setPersoon] = useState("");
  const [itemNaam, setItemNaam] = useState("");
  const [itemNummer, setItemNummer] = useState("");
  const [namenTextArea, setNamenTextArea] = useState("");
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [savedDialogOpen, setSavedDialogOpen] = useState(false);
  const [itemNummerReadOnly, setItemNummerReadOnly] = useState(false);

  useEffect(() => {
    if (location.state ? (location.state.itemID ? true : false) : false) {
      setItemNummerReadOnly(true);
    }
  }, []);

  const locatieletterconst = location.state
    ? location.state.locatieObject.locatieletter
    : "?";

  const TextAreaPersonen = () => {
    if (
      state.logged_in_user &&
      state.logged_in_user.isadmin === "1" &&
      (location.state ? location.state.itemID : null) !== null
    ) {
      return (
        <>
          <TextareaAutosize
            //   variant="standard" // <== changed this
            //   margin="normal"
            minRows={6}
            aria-label="minimum height"
            placeholder=""
            defaultValue={namenTextArea}
            style={{ border: "1px solid lightgray" }}
          />
        </>
      );
    } else {
      return <></>;
    }
  };

  const InvoerPersoonBijAddItem = () => {
    if (
      state.logged_in_user &&
      state.logged_in_user.isadmin === "1" &&
      (location.state ? location.state.itemID : null) !== null
    ) {
      return (
        <>
          <InputLabel id="demo-simple-select-label">Naam</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={persoon}
            label="Naam"
            // autoFocus
            onChange={handleSelectPersoon}
          >
            {ppersonen.map((pers) => (
              <MenuItem key={pers.id} value={pers}>
                {pers.naam}
              </MenuItem>
            ))}
          </Select>
          <Fab variant="extended" onClick={clickTogglePersoon}>
            <EmojiEmotionsIcon sx={{ mr: 1 }} />
            +/- Gebruiker
          </Fab>
        </>
      );
    } else {
      return <></>;
    }
  };

  const onErrorClose = () => {
    setErrorDialogOpen(false);
  };

  const onSavedClose = () => {
    setSavedDialogOpen(false);
  };

  const handleItemNaamChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("Itemnaam: ", event.target.value, "  ", itemNaam);
    setItemNaam(event.target.value);
  };
  const handleItemNummerChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const onlyNums = event.target.value.replace(/[^0-9]/g, "");
    console.log("Itemnummer: ", event.target.value, "  ", itemNummer, onlyNums);
    setItemNummer(onlyNums);
  };

  const handleSelectPersoon = (event: SelectChangeEvent) => {
    console.log("Je koos:", event.target.value);
    setPersoon(event.target.value);
  };
  const clickTogglePersoon = () => {
    console.log(
      "Toggle persoon uit Select click",
      "  [",
      persoon,
      "]   ",
      location.state ? location.state.itemID : null
    );
    if (persoon === null || persoon === undefined || persoon === "") {
      setErrorDialogOpen(true);
    } else {
      ToggleUser();
    }
  };

  const clickTerugItems = () => {
    console.log("Terug naar Items click", {
      state: state,
      locatieID: location.state ? location.state.locatieID : null,
    });
    navigate("/items", {
      state: {
        state: state,
        locatieID: location.state ? location.state.locatieID : null,
        locatieObject: location.state ? location.state.locatieObject : null,
      },
    });
  };

  const clickOpslaanItem = () => {
    let invoerfout = false;
    //FCL  : mag leeg zijn, voor sneller invoeren lijsten..
    // if (itemNaam === "") {
    //   invoerfout = true;
    // }
    if (invoerfout) {
      console.log("voer een locatienaam en locatieletter in");
      setErrorDialogOpen(true);
    } else {
      console.log("Sla locatie op click");
      SaveItemData();
    }
  };

  const makePersonenNaamTextArea = (namen) => {
    let namenTextArea = "";
    let eerste = true;
    let komma = "";
    namen.map(function (item) {
      if (eerste) {
        eerste = false;
      } else {
        komma = ", ";
      }
      if (item.naam) {
        return (namenTextArea = namenTextArea + komma + item.naam);
      } else {
        return namenTextArea;
      }
    });
    setNamenTextArea(namenTextArea);
  };

  const SaveItemData = () => {
    async function fetchData() {
      var data = JSON.stringify({
        itemid: location.state ? location.state.itemID : null,
        itemnaam: itemNaam,
        itemnummer: itemNummer,
        locatieid: location.state ? location.state.locatieID : null,
      });
      var config;
      if (1 === 2) {
        config = {
          method: "post",
          url: "https://iep.dordtonline.nl/api/additem.php",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
          data: data,
        };
      } else {
        config = {
          method: "post",
          url: "https://iep.dordtonline.nl/api/ladditem.php",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
          data: data,
        };
      }

      await axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          setSavedDialogOpen(true);
          navigate("/items", {
            state: {
              locatieID: location.state.locatieID,
              locatieObject: location.state.locatieObject,
            },
          });
        })
        .catch(function (error) {
          console.log(error);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    }
    fetchData();
  };

  useEffect(() => {
    async function fetchData() {
      //onzin parameter, het is select zonder where
      var data = JSON.stringify({
        itemID: 1,
      });
      //
      var config;
      if (1 === 2) {
        config = {
          method: "post",
          url: "https://iep.dordtonline.nl/api/namen.php",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
          data: data,
        };
      } else {
        config = {
          method: "post",
          url: "https://iep.dordtonline.nl/api/lnamen.php",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
          data: data,
        };
      }
      await axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          setPpersonen(response.data);

          dispatch({
            type: "FETCH_USERS",
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    }
    fetchData();
  }, []);

  const ToggleUser = () => {
    async function fetchData() {
      var data = JSON.stringify({
        item_id: location.state ? location.state.itemID : null,
        user_id: persoon.id,
      });
      //
      var config;
      if (1 === 2) {
        config = {
          method: "post",
          url: "https://iep.dordtonline.nl/api/toggleadditemuserZZZ.php",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
          data: data,
        };
      } else {
        config = {
          method: "post",
          url: "https://iep.dordtonline.nl/api/ltoggleadditemuser.php",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
          data: data,
        };
      }

      await axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          FetchItem();
        })
        .catch(function (error) {
          console.log(error);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    }
    fetchData();
  };

  const FetchItem = () => {
    var pItemID = location.state ? location.state.itemID : null;
    var pLocatieID = location.state ? location.state.locatieID : null;
    async function fetchData() {
      var data = JSON.stringify({
        itemID: pItemID,
        locatieID: pLocatieID,
      });
      var config;
      if (1 === 2) {
        config = {
          method: "post",
          url: "https://iep.dordtonline.nl/api/items.php",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
          data: data,
        };
      } else {
        config = {
          method: "post",
          url: "https://iep.dordtonline.nl/api/litems.php",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
          data: data,
        };
      }

      await axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          makePersonenNaamTextArea(response.data);
          setItemNaam(response.data[0].itemnaam);
          setItemNummer(response.data[0].itemnummer);
        })
        .catch(function (error) {
          console.log(error);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    }
    if (pItemID !== null && pItemID !== "") {
      fetchData();
    }
  };

  useEffect(() => {
    FetchItem();
  }, []);

  return (
    <>
      <div className="flex flex-col items-center min-h-screen text-md mt-10 mx-4 relative">
        <div>
          <h1 className="mt-0 mb-2 text-2xl font-normal leading-normal text-pink-800">
            Bewerk Item
          </h1>
        </div>
        <FormControl sx={{ margin_left: 50 }}>
          <Stack spacing={2}>
            <InvoerPersoonBijAddItem />

            <TextAreaPersonen />

            <TextField
              id="outlined-locatieletter-input"
              label="Item letter"
              variant="outlined"
              // value={locatieLetter}
              value={locatieletterconst}
            />

            <TextField
              id="outlined-itemnummer-input"
              label="Item nummer"
              variant="outlined"
              // inputProps={{ readOnly: itemNummerReadOnly }}
              inputProps={{ readOnly: false }}
              value={itemNummer}
              onChange={handleItemNummerChange}
            />

            <TextField
              id="outlined-itemnaam-input"
              label="Item"
              variant="outlined"
              value={itemNaam}
              onChange={handleItemNaamChange}
            />
          </Stack>
          <br></br>
          <Stack spacing={1} direction="row">
            <Button
              sx={{ width: 150 }}
              variant="contained"
              onClick={clickOpslaanItem}
            >
              Opslaan
            </Button>

            <Button
              sx={{ width: 150 }}
              variant="contained"
              onClick={clickTerugItems}
            >
              Terug
            </Button>
          </Stack>
          <br></br>
        </FormControl>
      </div>

      <DialogIepApp
        icons={
          <>
            <SentimentSatisfiedAltIcon />
          </>
        }
        Transition={Transition}
        dialogopen={savedDialogOpen}
        onClose={onSavedClose}
        koptekst={"Het voorwerp is opgeslagen"}
        melding={"Het voorwerp is opgeslagen."}
      />
      <DialogIepApp
        icons={
          <>
            <SentimentVeryDissatisfiedIcon />
            <FlashOnIcon />
          </>
        }
        Transition={Transition}
        dialogopen={errorDialogOpen}
        onClose={onErrorClose}
        koptekst={"Voer een itemnaam in of kies een persoon?"}
        melding={"Voer een Item naam in of kies een persoon."}
      />
    </>
  );
};

export default AddItem;
