import { Routes, Route } from "react-router-dom"
import { Provider } from "./useGlobalState";
import reducer from './reducers';
import Home from './pages/Home.tsx';
import Locaties from './pages/Locaties.tsx';
import Menu from './pages/Menu.tsx';
import Items from './pages/Items.tsx';
import AddLocatie from './pages/AddLocatie.tsx';
import AddItem from './pages/AddItem.tsx';
import AddUsers from './pages/AddUsers.tsx';

import './App.css';

let initialState = [];

//?sessionStorage.removeItem("iep_app_session");

let persoon = sessionStorage.getItem("persoon");
console.log("persoon", persoon);
function App() {

  return (
    <Provider reducer={reducer} initialState={initialState}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/locaties" element={<Locaties />} />
        <Route path="/items" element={<Items />} />
        <Route path="/addusers" element={<AddUsers />} />
        <Route path="/addlocatie" element={<AddLocatie />} />
        <Route path="/additem" element={<AddItem />} />
        {/* <Route exact path="/" render={() => <Redirect to="/locatie" />} />
          <Route render={() => <Redirect to="/locatie" />} /> */}
      </Routes>
    </Provider>
  )
}


export default App;
