import React, { useEffect, useState } from "react";
import { useGlobalState } from "../useGlobalState";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import axios from "axios";
import { Stack } from "@mui/system";

const Locaties = () => {
  const navigate = useNavigate();

  const [state, dispatch] = useGlobalState();
  const [locatieslist, setLocatiesList] = useState([]);

  // let currentSessionPersoon = JSON.parse(
  //   sessionStorage.getItem("iep_app_session")
  // );

  const clickAddLocatie = () => {
    console.log("Add locatie click");
    navigate("/addlocatie");
  };

  const clickTerugButton = () => {
    console.log("Terug naar Menu click");
    navigate("/menu");
  };

  const clickEditLocatie = (event, key) => {
    console.log("Edit Locatie click", event, key);
    navigate("/addlocatie", { state: { locatieID: key.locatieID } });
  };

  const clickSelectLocatie = (event, locID, loc) => {
    console.log("click Select locatie, navigeer naar items", event, locID, loc);
    navigate("/items", { state: { locatieID: locID, locatieObject: loc } });
  };

  const EditLocatieButton = (locatieID) => {
    if (state.logged_in_user && state.logged_in_user.isadmin === "1") {
      return (
        <>
          <div className="flex-none p-1 justify-center">
            <button
              className="text-white rounded-full bg-blue-400 p-1"
              onClick={(event) => clickEditLocatie(event, locatieID)}
              key={locatieID}
            >
              &nbsp;&nbsp;&nbsp;Edit&nbsp;&nbsp;&nbsp;
            </button>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };

  const ListLocatieItem = (loc) => {
    return (
      <div className="flex mx-2 mb-2 bg-white shadow-2xl">
        <div
          className="flex-none w-14 bg-slate-500 text-white p-1"
          onClick={(event) => clickSelectLocatie(event, loc.loc.id, loc.loc)}
        >
          {loc.loc.locatieletter}
        </div>
        <div
          className="flex flex-auto p-1"
          onClick={(event) => clickSelectLocatie(event, loc.loc.id, loc.loc)}
        >
          {loc.loc.locatienaam}
        </div>
        <EditLocatieButton locatieID={loc.loc.id} />
      </div>
    );
  };

  const ListLocaties = () => {
    if (Array.isArray(locatieslist) && locatieslist.length !== 0) {
      return (
        <>
          {locatieslist.map((locs) => (
            <ListLocatieItem loc={locs} key={locs.id} />
          ))}
        </>
      );
    } else {
      return (
        <div>
          <h1>De locatie lijst is nog leeg, voeg een locatie toe.</h1>
        </div>
      );
    }
  };

  // const KopText = () => {
  //   return (
  //     <>
  //       <h1 className="top-0 ml-2 mb-2 text-2xl font-normal leading-normal text-pink-800">
  //         Locaties
  //       </h1>
  //     </>
  //   );
  // };

  const KopText = () => {
    return (
        <h1 className="text-4xl text-red-500">Locaties</h1>
    );
  };

  const AddLocatieButton = () => {
    if (state.logged_in_user && state.logged_in_user.isadmin === "1") {
      return (
        <>
          {/* <div className="top-10 ml-2"> */}
            <Button
              sx={{ width: 130 }}
              variant="contained"
              onClick={clickAddLocatie}
            >
              Toevoegen
            </Button>
          {/* </div> */}
         </>

      );
    } else {
      return <></>;
    }
  };

  const TerugButton = () => {
    return (
      <>
        {/* <div className="top-10 ml-2 "> */}
          <Button
            sx={{ width: 130 }}
            variant="contained"
            onClick={clickTerugButton}
          >
            Terug
          </Button>
        {/* </div> */}
      </>
    );
  };

  useEffect(() => {
    if (!state.logged_in_user) {
      navigate("/");
    }
    async function fetchData() {
      var data = JSON.stringify({
        firstName: "Fred",
        lastName: "Flintstone",
      });
      var config;
      // if (1 == 2) {
      //   config = {
      //     method: "post",
      //     url: "https://iep.dordtonline.nl/api/locaties.php",
      //     headers: {
      //       "Content-Type": "application/json",
      //       Accept: "*/*",
      //     },
      //     data: data,
      //   };
      // } else {
        config = {
          method: "post",
          url: "https://iep.dordtonline.nl/api/llocaties.php",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
          data: data,
        // };
      }
      await axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          setLocatiesList(response.data);
          dispatch({
            type: "FETCH_LOCATIES",
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    }
    fetchData();
  }, []);

  return (
    <>
      <div className="flex flex-col fixed top-0 w-full justify-center items-center space-y-4 py-4 bg-indigo-200 ">
        <KopText />
        <div className="flex flex-row space-x-4">
          <AddLocatieButton />
          <TerugButton />
        </div>
      </div>

      {/* <div className="max-w-3xl mx-auto mt-12 text-slate-800">
        <div className="fixed">
          <Stack spacing={1} direction="row">
            <KopText />
            <AddLocatieButton />
            <TerugButton />
          </Stack>
        </div>
      </div> */}

      <div className="max-w-3xl mx-auto mt-32 text-slate-800">
        {/* <br></br>
        <br></br>
        <br></br> */}
        <ListLocaties />
      </div>

      {/* <div className="max-w-3xl mx-auto mt-12 text-slate-800">
        <div className="fixed">
          <Stack spacing={1} direction="row">
            <KopText />
            <AddLocatieButton />
            <TerugButton />
          </Stack>
        </div>
      </div>
      <div className="max-w-3xl mx-auto mt-12 text-slate-800">
        <br></br>
        <br></br>
        <br></br>
        <ListLocaties />
      </div> */}
    </>
  );
};

export default Locaties;
