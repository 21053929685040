import React from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import Slide from "@mui/material";
// import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
// import FlashOnIcon from "@mui/icons-material/FlashOn";

// import { TransitionProps } from "@mui/material/transitions";

// const Transition = React.forwardRef(function Transition(
//   props: TransitionProps & { children: React.ReactElement<any, any> },
//   ref: React.Ref<unknown>
// ) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const DialogIepApp: React.FunctionComponent = (props) => {
  // function DialogIepApp (props){
  return (
    <>
      <Dialog
        open={props.dialogopen}
        TransitionComponent={props.Transition}
        keepMounted
        onClose={props.onClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="id">
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>{props.koptekst}</Box>
            <Box>
              <IconButton onClick={props.onClose}>
                {props.icons}
                {/* <EmojiEmotionsIcon /> */}
                {/* <FlashOnIcon /> */}
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.melding}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Sluit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogIepApp;
