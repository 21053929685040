import React from "react";
// import { useGlobalState } from "../useGlobalState";
import { useNavigate } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import { Stack } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Fab from "@mui/material/Fab";

const Menu = () => {
  //  const {state} = useLocation();
  const navigate = useNavigate();
  // const location = useLocation();
  //  const [state, dispatch] = useGlobalState();

  const [stateDrawer, setStateDrawer] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setStateDrawer({ ...stateDrawer, [anchor]: open });
    };

  let currentSessionValues = JSON.parse(
    sessionStorage.getItem("iep_app_session")
  );

  const clickTerug = () => {
    console.log("Home click");
    navigate("/");
  };

  const clickLocaties = () => {
    console.log("Locaties click");
    navigate("/locaties");
  };
  const clickUsers = () => {
    console.log("Home click");
    navigate("/addusers");
  };

  const ButtonToHome = () => {
    return (
      <>
        <Button
          sx={{ width: 150 }}
          variant="contained"
          // margin="dense"
          onClick={clickTerug}
        >
          Terug
        </Button>
      </>
    );
  };

  const ButtonToLocaties = () => {
    return (
      <>
        <Button sx={{ width: 150 }} variant="contained" onClick={clickLocaties}>
          Locaties
        </Button>
      </>
    );
  };

  const ButtonToUsers = () => {
    if (currentSessionValues && currentSessionValues.isadmin === "1") {
      return (
        <>
          <Button
            sx={{ width: 150 }}
            variant="contained"
            // margin="dense"
            onClick={clickUsers}
          >
            Gebruikers
          </Button>
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <div className="flex flex-col items-center min-h-screen text-md mt-10 mx-4 relative">
        {/* <> */}
        <div>
          <h1 className="mt-0 mb-2 text-2xl font-normal leading-normal text-pink-800">
            Maak een keuze
          </h1>
        </div>
        <Stack spacing={2}>
          <ButtonToLocaties />
          <ButtonToUsers />
          <ButtonToHome />
        </Stack>
        {/* </> */}

        <br />

        <Fab
          color="primary"
          aria-label="right"
          onClick={toggleDrawer("right", true)}
        >
          <HelpOutlineIcon />
        </Fab>
        <Drawer
          anchor={"right"}
          open={stateDrawer["right"]}
          onClose={toggleDrawer("right", false)}
        >
          <br />
          &nbsp;Dit kan een helptekst worden...&nbsp;
          <Button onClick={toggleDrawer("right", false)}>{"X"}</Button>
        </Drawer>
      </div>
    </>
  );
};

export default Menu;
