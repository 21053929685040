import React, { useEffect, useState } from "react";
import { useGlobalState } from "../useGlobalState";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Stack,
} from "@mui/material";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import axios from "axios";
import { TransitionProps } from "@mui/material/transitions";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//test
const AddLocatie = (route, navagation) => {
  const [state, dispatch] = useGlobalState();
  const navigate = useNavigate();
  const location = useLocation();
  const [locatieId, setLocatieId] = useState("");
  const [ilocatienaam, setILocatienaam] = useState("");
  const [ilocatieletter, setILocatieletter] = useState("");
  const [dialogopen, setDialogOpen] = useState(false);

  const onClose = () => {
    setDialogOpen(false);
  };

  const handleLetterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = event.target.value.toUpperCase();
    console.log(event.target.value);
    if (event.target.value.length > 1) {
      console.log(event.target.value[event.target.value.length - 1]);
      event.target.value = event.target.value[event.target.value.length - 1];
      if (event.target.value === undefined) {
        event.target.value = "";
      }
    }
    setILocatieletter(event.target.value);
  };

  const handleLocatieChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setILocatienaam(event.target.value);
  };

  const clickTerugLocatie = () => {
    console.log("Terug naar locatie click");
    navigate("/locaties");
  };

  const clickOpslaanLocatie = () => {
    console.log("Sla locatie op click");
    let invoerfout = false;
    if (ilocatienaam === "") {
      invoerfout = true;
    }
    if (ilocatieletter === "") {
      invoerfout = true;
    }
    if (invoerfout) {
      console.log("voer een locatienaam en locatieletter in");
      setDialogOpen(true);
    } else {
      AddLocatieData();
    }
  };

  const AddLocatieData = () => {
    async function fetchData() {
      var data = JSON.stringify({
        locatieid: location.state ? location.state.locatieID : null,
        locatieletter: ilocatieletter,
        locatienaam: ilocatienaam,
      });
      var config;
      if (1 == 1) {
        config = {
          method: "post",
          url: "https://iep.dordtonline.nl/api/addlocaties.php",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
          data: data,
        };
      } else {
        config = {
          method: "post",
          url: "https://iep.dordtonline.nl/api/laddlocaties.php",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
          data: data,
        };
      }

      await axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          //setLocatiesList(response.data);
          dispatch({
            type: "ADD_LOCATIE",
            payload: response.data,
          });
          navigate("/locaties");
        })
        .catch(function (error) {
          console.log(error);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    }
    fetchData();
  };

  useEffect(() => {
    if (location.state !== null) {
      async function fetchData() {
        var data = JSON.stringify({
          locatieID: location.state.locatieID,
        });
        var config;
        if (1 == 1) {
          config = {
            method: "post",
            url: "https://iep.dordtonline.nl/api/lgetlocatie.php",
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
            },
            data: data,
          };
        } else {
          config = {
            method: "post",
            url: "https://iep.dordtonline.nl/api/lgetlocatie.php",
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
            },
            data: data,
          };
        }

        await axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
            dispatch({
              type: "GET_LOCATIE",
              payload: response.data,
            });
            setILocatieletter(response.data.locatieletter);
            setILocatienaam(response.data.locatienaam);
            console.log(JSON.stringify(state));
          })
          .catch(function (error) {
            console.log(error);
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("Error", error.message);
            }
          });
      }
      fetchData();
      //te laat
    }
  }, []);

  return (
    <>
      <div className="flex flex-col items-center min-h-screen text-md mt-10 mx-4 relative">
        <div>
          <h1 className="mt-0 mb-2 text-2xl font-normal leading-normal text-pink-800">
            Bewerk Locatie
          </h1>
        </div>
        {/* {ilocatienaam}---{ilocatieletter} */}
        {/* {location.state ? location.state.locatieID : "??"} */}
        <FormControl sx={{ margin_left: 50 }}>
          <TextField
            id="outlined-password-input"
            label="Locatie letter"
            variant="outlined"
            margin="none"
            autoFocus
            value={
              state.getlocatie && location.state
                ? state.getlocatie.locatieletter
                : ilocatieletter
            }
            onChange={handleLetterChange}
          />
          <TextField
            id="outlined-password-input"
            label="Locatie"
            variant="outlined"
            margin="normal"
            value={ilocatienaam}
            onChange={handleLocatieChange}
          />
          <Stack spacing={1} direction="row">
            <Button
              sx={{ width: 150 }}
              variant="contained"
              // margin="dense"
              onClick={clickOpslaanLocatie}
            >
              Opslaan
            </Button>

            <Button
              sx={{ width: 150 }}
              variant="contained"
              // margin="dense"
              onClick={clickTerugLocatie}
            >
              Terug
            </Button>
          </Stack>
          <br></br>
        </FormControl>
      </div>
      <Dialog
        open={dialogopen}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="id">
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>{"letter of locatie fout?"}</Box>
            <Box>
              <IconButton onClick={onClose}>
                {/* <CloseIcon /> */}
                <EmojiEmotionsIcon />
                <FlashOnIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Voer een Letter en Locatie in.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Sluit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddLocatie;
