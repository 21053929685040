import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Box from "@mui/material/Box";
// @ts-ignore
import DialogIepApp from "../components/DialogIepApp.tsx";
import { Slide, Stack } from "@mui/material";
//import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
//import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import axios from "axios";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddUsers = (route, navagation) => {
  //const [state, dispatch] = useGlobalState();
  const navigate = useNavigate();
  //const location = useLocation();
  const [usernaam, setUsernaam] = useState("");
  const [pin, setPin] = useState("");
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [succesDialogOpen, setSuccesDialogOpen] = useState(false);
  const [namenTextArea, setNamenTextArea] = useState("");

  const onCloseError = () => {
    setErrorDialogOpen(false);
  };

  const onCloseSucces = () => {
    setSuccesDialogOpen(false);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsernaam(event.target.value);
  };

  const handlePinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPin(event.target.value);
  };

  const clickTerugMenu = () => {
    console.log("Terug naar menu click");
    navigate("/menu");
  };

  const clickOpslaanUser = () => {
    console.log("Sla user op click");
    let invoerfout = false;
    if (usernaam === "") {
      invoerfout = true;
    }
    if (pin === "") {
      invoerfout = true;
    }
    if (invoerfout) {
      console.log("voer een naam en pin in");
      setErrorDialogOpen(true);
    } else {
      AddUserData();
      //TODO navigate("/menu");
    }
  };

  const makePersonenNaamTextArea = (namen) => {
    let namenTextArea = "";
    let eerste = true;
    let komma = "";
    namen.map(function (item) {
      if (eerste) {
        eerste = false;
      } else {
        komma = ", ";
      }
      return (namenTextArea = namenTextArea + komma + item.naam);
    });
    setNamenTextArea(namenTextArea);
  };
  const GetUsersTextArea = () => {
    async function fetchData() {
      var data = JSON.stringify({
        id: 1,
      });
      //
      var config;
      if (1 === 2) {
        config = {
          method: "post",
          url: "https://iep.dordtonline.nl/api/namen.php",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
          data: data,
        };
      } else {
        config = {
          method: "post",
          url: "https://iep.dordtonline.nl/api/lnamen.php",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
          data: data,
        };
      }

      await axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          makePersonenNaamTextArea(response.data);
        })
        .catch(function (error) {
          console.log(error);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    }
    fetchData();
  };

  useEffect(() => {
    GetUsersTextArea();
  }, []);

  const AddUserData = () => {
    async function fetchData() {
      var data = JSON.stringify({
        usernaam: usernaam,
        pin: pin,
      });
      var config;
      if (1 == 2) {
        config = {
          method: "post",
          url: "https://iep.dordtonline.nl/api/adduser.php",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
          data: data,
        };
      } else {
        config = {
          method: "post",
          url: "https://iep.dordtonline.nl/api/ladduser.php",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
          data: data,
        };
      }
      await axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          GetUsersTextArea();
          setSuccesDialogOpen(true);
        })
        .catch(function (error) {
          console.log(error);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    }
    fetchData();
  };

  return (
    <>
      {/* Ruben */}
      {/* <div className="flex flex-col fixed top-0 bg-white w-full justify-center items-center space-y-4 py-4">
        <h1 className="text-4xl text-red-500">Hallo header</h1>
        <div className="flex flex-row space-x-4">
          <div className="rounded-md bg-blue-500 text-white px-4 py-2">
            Hallo knop 1
          </div>
          <div className="rounded-md bg-blue-500 text-white px-4 py-2">
            Hallo knop 2
          </div>
        </div>
      </div> */}

      <div className="flex flex-col items-center min-h-screen text-md mt-10 mx-4 relative">
        <div>
          <h1 className="mt-0 mb-2 text-2xl font-normal leading-normal text-pink-800">
            Voeg Persoon toe
          </h1>
        </div>

        <FormControl sx={{ margin_left: 50 }}>
          <TextareaAutosize
            minRows={6}
            aria-label="minimum height"
            placeholder=""
            value={namenTextArea}
            style={{ border: "1px solid lightgray" }}
          />

          <TextField
            id="outlined-naam-input"
            label="Naam"
            variant="outlined"
            margin="normal"
            value={usernaam}
            onChange={handleNameChange}
          />
          <TextField
            id="outlined-password-input"
            label="Pin Code"
            variant="outlined"
            margin="normal"
            value={pin}
            onChange={handlePinChange}
          />

          <Stack spacing={1} direction="row">
            <Button
              sx={{ width: 150 }}
              variant="contained"
              // margin="dense"
              onClick={clickOpslaanUser}
            >
              Opslaan
            </Button>

            <Button
              sx={{ width: 150 }}
              variant="contained"
              // margin="dense"
              onClick={clickTerugMenu}
            >
              Terug
            </Button>
          </Stack>
          <br></br>
        </FormControl>
      </div>

      <DialogIepApp
        Transition={Transition}
        icon={
          <>
            <SentimentSatisfiedAltIcon />
            <FlashOnIcon />
          </>
        }
        dialogopen={succesDialogOpen}
        onClose={onCloseSucces}
        koptekst={"Succes!"}
        melding={"De naam en pin zijn opgeslagen."}
      />
      <DialogIepApp
        Transition={Transition}
        icon={
          <>
            <SentimentSatisfiedAltIcon />
            <FlashOnIcon />
          </>
        }
        dialogopen={errorDialogOpen}
        onClose={onCloseError}
        koptekst={"naam of pin fout?"}
        melding={"Voer een naam en pin in."}
      />
    </>
  );
};

export default AddUsers;
